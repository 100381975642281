import { useContext, Fragment, useRef, useState, useEffect } from "react";
// import { useContext, Fragment, useRef } from "react";
import { Button, Accordion, Form, Alert } from "react-bootstrap";
import { playgroundContext } from "../context/PlaygroundContext";
import PlaygroundForm from "../components/PlaygroundForm";
import SmallDotPulse from "../components/DotPulse/SmallDotPulse";
import { authContext } from "../context/AuthContext";
import { restrictAccessModalContext } from "../context/RestrictAccessModalContext";


function Playground() {
  const { playground, setPlayground, resetPlayground } =
    useContext(playgroundContext);
  
  const { openRestrictAccessModal } = useContext(restrictAccessModalContext);

  const { currentUser } = useContext(authContext);

  const controllerRef = useRef(null);

  const [modelOptions, setModelOptions] = useState([]);

  useEffect(() => {
    const fetchModelOptions = async () => {
      let token = "";
      try {
        token = await currentUser.getIdToken(true);
      }
      catch {
        token = "null";
      }

      try {
        const base_url = "https://api.playground.atacana.ai";
        const response = await fetch(`${base_url}/models/chat/completions`, {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            }
          });
        const data = await response.json();
        setModelOptions(data); // Adjust based on the structure of your response
      } catch (error) {
        console.error("Error fetching model options:", error);
      }
    };
    fetchModelOptions();
  }, [currentUser]);

  const maxLengthHandler = (event) => {
    setPlayground({ ...playground, maxLength: event.target.value });
  };

  const closeAlert = () => {
    setPlayground({ ...playground, responseAlert: "" });
  };

  const mapToRange = (value, low, high) => {
    if (value < low) {
      return low;
    } else if (value > high) {
      return high;
    } else {
      return value;
    }
  };

  const temperatureHandler = (value, low, high) => {
    setPlayground({ ...playground, temperature: mapToRange(value, low, high) });
  };

  if (!currentUser?.emailVerified) {
    openRestrictAccessModal();
    return <></>
  }

  return (
    <Fragment>
      <PlaygroundForm controllerRef={controllerRef} />
      <div className="settings-bar">
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>Feature Settings</Accordion.Header>
            <Accordion.Body>
              <div className="section">
                <Form.Label>Model</Form.Label>
                <Form.Select
                  onChange={(event) => {
                    setPlayground({ ...playground, model: event.target.value });
                  }}
                  value={playground.model}
                  disabled={playground.spinner}
                >
                  {modelOptions.map((model) => (
                    <option value={model}>
                      {model}
                    </option>
                  ))}
                </Form.Select>
              </div>
              {playground.model != "biogpt" ? (
                <div className="section">
                  <div className="range-label-container">
                    <Form.Label>Temperature</Form.Label>
                    <Form.Control
                      type="number"
                      className="range-input"
                      value={playground.temperature}
                      min={0}
                      max={1}
                      step={0.01}
                      onChange={(event) =>
                        temperatureHandler(event.target.value, 0, 1)
                      }
                    />
                  </div>
                  <Form.Range
                    form="form"
                    name="temperature"
                    value={playground.temperature}
                    min={0}
                    max={1}
                    step={0.01}
                    onChange={(event) =>
                      temperatureHandler(event.target.value, 0, 1)
                    }
                  />
                </div>
              ) : (
                <></>
              )}
              <div className="section">
                <Form.Label>Output Length</Form.Label>
                <div>
                  <input
                    inline="true"
                    type="radio"
                    name="maxLength"
                    value={256}
                    checked={playground.maxLength == 256}
                    onChange={maxLengthHandler}
                  />
                  <input
                    inline="true"
                    type="radio"
                    name="maxLength"
                    value={512}
                    checked={playground.maxLength == 512}
                    onChange={maxLengthHandler}
                  />
                  <input
                    inline="true"
                    type="radio"
                    name="maxLength"
                    value={768}
                    checked={playground.maxLength == 768}
                    onChange={maxLengthHandler}
                  />
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <div className="settings-bottom">
            <Button type="submit" variant="primary" form="playgroundForm" hidden={playground.spinner}>
              Compose
            </Button>
            <Button variant="secondary" onClick={e => {controllerRef.current?.abort()}} hidden={!playground.spinner}>
              Cancel
            </Button>
          <Button variant="primary" onClick={resetPlayground}>
            Reset
          </Button>
        </div>
      </div>

      <div className="content">
        <div className="box" style={{ height: "100%", maxWidth: "100%" }}>
          <div
            className="title"
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr",
              gap: "1rem",
            }}
          >
            {playground.spinner ? (
              <div
                style={{
                  alignSelf: "center",
                  justifySelf: "left",
                  padding: "0.5rem 2rem 0.5rem 2rem",
                }}
              >
                <SmallDotPulse />
              </div>
            ) : (
              <div></div>
            )}
            <div style={{ justifySelf: "center" }}>Playground</div>
          </div>
            {playground.responseAlert && (
              <Alert variant="warning" onClose={closeAlert} dismissible>
                <span>{playground.responseAlert}</span>
              </Alert>
            )}
          <div className="container">
            <Form.Control
              as="textarea"
              placeholder={playground.placeholder}
              name="textarea"
              onChange={(event) =>
                setPlayground({ ...playground, textarea: event.target.value })
              }
              value={playground.textarea}
              form="playgroundForm"
              required
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Playground;
