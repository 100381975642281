import { useContext } from "react";
import { Form, Accordion, Button } from "react-bootstrap";
import { featureContext } from "../context/Feature";
import { formDataContext } from "../context/FormData";

function FeatureSettings() {
  const { feature } = useContext(featureContext);
  const { formData, updateFormData, resetFeatureOnFormData } =
    useContext(formDataContext);

  const maxLengthHandler = (event) => {
    updateFormData(feature, "maxLength", event.target.value);
  };

  const mapToRange = (value, low, high) => {
    if (value < low) {
      return low;
    } else if (value > high) {
      return high;
    } else {
      return value;
    }
  };

  const rangeHandler = (field, value, low, high) => {
    updateFormData(feature, field, mapToRange(value, low, high));
  };

  return (
    <div className="settings-bar">
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>Feature Settings</Accordion.Header>
          <Accordion.Body>
            {feature == 0 && (
              <div className="section">
                <Form.Label>Topic of Interest</Form.Label>
                <Form.Control
                  type="text"
                  form="form"
                  name="topicOfInterest"
                  value={formData[feature].topicOfInterest}
                  onChange={(event) =>
                    updateFormData(
                      feature,
                      "topicOfInterest",
                      event.target.value
                    )
                  }
                />
              </div>
            )}
            {feature == 0 && (
              <div className="section">
                <div className="range-label-container">
                  <Form.Label>Number of Bullet Points</Form.Label>
                  <Form.Control
                    type="number"
                    className="range-input"
                    value={formData[feature].numberOfBulletPoints}
                    min={1}
                    max={10}
                    step={1}
                    onChange={(event) => rangeHandler("numberOfBulletPoints", event.target.value, 0, 10)}
                    onBlur={(event) => rangeHandler("numberOfBulletPoints", event.target.value, 1, 10)}
                  />
                </div>
                <Form.Range
                  form="form"
                  name="numberOfBulletPoints"
                  min={1}
                  max={10}
                  step={1}
                  value={formData[feature].numberOfBulletPoints}
                  onChange={(event) => rangeHandler("numberOfBulletPoints", event.target.value, 1, 10)}
                />
              </div>
            )}
            {feature == 1 && (
              <div className="section">
                <div className="range-label-container">
                  <Form.Label>Number of Suggestions</Form.Label>
                  <Form.Control
                    type="number"
                    className="range-input"
                    value={formData[feature].numberOfSuggestions}
                    min={1}
                    max={10}
                    step={1}
                    onChange={(event) => rangeHandler("numberOfSuggestions", event.target.value, 0, 10)}
                    onBlur={(event) => rangeHandler("numberOfBulletPoints", event.target.value, 1, 10)}
                  />
                </div>
                <Form.Range
                  form="form"
                  name="numberOfSuggestions"
                  min={1}
                  max={10}
                  step={1}
                  value={formData[feature].numberOfSuggestions}
                  onChange={(event) => rangeHandler("numberOfSuggestions", event.target.value, 1, 10)}
                />
              </div>
            )}
            <div className="section">
              <Form.Label>Output Length</Form.Label>
              <div>
                <input
                  inline="true"
                  type="radio"
                  name="maxLength"
                  form="form"
                  value={256}
                  checked={formData[feature].maxLength == 256}
                  onChange={maxLengthHandler}
                />
                <input
                  inline="true"
                  type="radio"
                  name="maxLength"
                  form="form"
                  value={512}
                  checked={formData[feature].maxLength == 512}
                  onChange={maxLengthHandler}
                />
                <input
                  inline="true"
                  type="radio"
                  name="maxLength"
                  form="form"
                  value={768}
                  checked={formData[feature].maxLength == 768}
                  onChange={maxLengthHandler}
                />
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header
            style={{ borderTop: "1px solid var(--light-border)" }}
          >
            Model Settings
          </Accordion.Header>
          <Accordion.Body>
            {feature in [0, 1, 2] && (
              <div className="section">
                <div className="range-label-container">
                  <Form.Label>Temperature</Form.Label>
                  <Form.Control
                    type="number"
                    className="range-input"
                    value={formData[feature].temperature}
                    min={0}
                    max={1}
                    step={0.01}
                    onChange={(event) => rangeHandler("temperature", event.target.value, 0, 1)}
                  />
                </div>
                <Form.Range
                  form="form"
                  name="temperature"
                  value={formData[feature].temperature}
                  min={0}
                  max={1}
                  step={0.01}
                  onChange={(event) => rangeHandler("temperature", event.target.value, 0, 1)}
                />
              </div>
            )}
            {feature in [0, 1, 2] && (
              <div className="section">
                <Form.Label>Append Custom Instructions</Form.Label>
                <Form.Control
                  type="text"
                  form="form"
                  name="attachment"
                  value={formData[feature].attachment}
                  onChange={(event) =>
                    updateFormData(
                      feature,
                      "attachment",
                      event.target.value
                    )
                  }
                />
              </div>
            )}
            {feature in [0, 1, 2] && (
              <div className="section">
                <Form.Label>Tone of Voice</Form.Label>
                <Form.Control
                  type="text"
                  form="form"
                  name="toneOfVoice"
                  value={formData[feature].toneOfVoice}
                  onChange={(event) =>
                    updateFormData(feature, "toneOfVoice", event.target.value)
                  }
                />
              </div>
            )}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <div className="settings-bottom">
        <Button type="submit" variant="primary" form="form">
          Compose
        </Button>
        <Button
          variant="primary"
          onClick={() => resetFeatureOnFormData(feature)}
        >
          Reset
        </Button>
      </div>
    </div>
  );
}

export default FeatureSettings;
