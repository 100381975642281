import BootstrapNavbar from "react-bootstrap/Navbar";
import { Link } from "react-router-dom";
import { Container, Nav } from "react-bootstrap";
import logo from "../logo.png";
import { authContext } from "../context/AuthContext";
import { useContext } from "react";
import {auth} from '../firebase'
import { signOut } from "firebase/auth";
import { restrictAccessModalContext } from "../context/RestrictAccessModalContext";
import { useNavigate } from "react-router-dom";

function Navbar() {
  const { currentUser, setCurrentUser } = useContext(authContext);
  const { openRestrictAccessModal } = useContext(restrictAccessModalContext);
  const navigate = useNavigate();

  const signout = async () => {
    await signOut(auth);
    await setCurrentUser(null, navigate("/"));
    window.location.reload();
  };

  return (
    <BootstrapNavbar bg="white" expand="sm">
      <Container fluid style={{ paddingLeft: "2rem", paddingRight: "2rem" }}>
        <BootstrapNavbar.Brand href="/">
          <img
            src={logo}
            height={44}
            className="d-inline-block align-top"
            alt="Playground logo"
          />
        </BootstrapNavbar.Brand>
        <BootstrapNavbar.Toggle aria-controls="navbar-nav" />
        <BootstrapNavbar.Collapse
          id="navbar-nav"
          className="justify-content-end"
        >
          <Nav>
            { !(currentUser && !currentUser.emailVerified) ? (
              <Nav.Link as={Link} to="/">
              Home
              </Nav.Link>
            ) : (
              <Nav.Link onClick={openRestrictAccessModal}>
              Home
              </Nav.Link>
            )}
            { currentUser?.emailVerified ? (
              <Nav.Link as={Link} to="/playground">
              Playground
              </Nav.Link>
            ) : (
              <Nav.Link onClick={openRestrictAccessModal}>
              Playground
              </Nav.Link>
            )}
            <Nav.Link href="https://github.com/PharmaBrain" target="_blank" active={false}>
              About Us
            </Nav.Link>
            {currentUser ? (
              <Nav.Link onClick={signout}>
                Sign Out
              </Nav.Link>
            ) : (
              <Nav.Link as={Link} to="/sign-in">Sign In</Nav.Link>
            )}
          </Nav>
        </BootstrapNavbar.Collapse>
      </Container>
    </BootstrapNavbar>
  );
}

export default Navbar;
