import { useContext } from "react";
import { Form } from "react-bootstrap";
import { featureContext } from "../context/Feature";
import { formDataContext } from "../context/FormData";
import { ReactComponent as InputIcon } from "../arrow90deg.svg";
import { ReactComponent as TrashIcon } from "../trash.svg";

function FeatureInput({ style }) {
  const { feature } = useContext(featureContext);
  const { formData, updateFormData } = useContext(formDataContext);

  return (
    <div className="box" style={style}>
      <div
        className="title"
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr 1fr",
          gap: "1rem",
        }}
      >
        <div style={{ display: "flex", gap: "5px", justifySelf: "left" }}>
          <button
            type="button"
            className="btn btn-outline-secondary btn-sm"
            onClick={() =>
              updateFormData(
                feature,
                "textInput",
                formData[(((feature - 1) % 3) + 3) % 3].textInput
              )
            }
          >
            <InputIcon />
          </button>
          <button
            type="button"
            className="btn btn-outline-secondary btn-sm"
            onClick={() =>
              updateFormData(
                feature,
                "textInput",
                formData[(((feature + 1) % 3) + 3) % 3].textInput
              )
            }
            style={{ transform: "scale(-1, 1)" }}
          >
            <InputIcon />
          </button>
          <button
            type="button"
            className="btn btn-outline-secondary btn-sm"
            onClick={() => updateFormData(feature, "textInput", "")}
          >
            <TrashIcon />
          </button>
        </div>
        <div style={{ justifySelf: "center" }}>News</div>
      </div>
      <Form.Control
        as="textarea"
        form="form"
        placeholder="Please copy and paste your article"
        name="textInput"
        value={formData[feature].textInput}
        onChange={(event) =>
          updateFormData(feature, "textInput", event.target.value)
        }
        required
      />
    </div>
  );
}
export default FeatureInput;
