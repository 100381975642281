import styles from "./Login.module.css";
import { Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useState } from "react";
import { auth } from "../firebase";
import { sendPasswordResetEmail } from "firebase/auth";

function ForgotPassword() {
  const [email, setEmail] = useState("");
  //   const [error, setError] = useState("");
  //   const { setTimeActive } = useAuthValue();

  const forgot_password = (e) => {
    e.preventDefault();
    sendPasswordResetEmail(auth, email)
      .then(() => {
        alert("Password reset email sent.");
      })
      .catch((err) => alert("User not found."));
  };

  return (
    <div className={styles.container}>
      <div className={styles.overlay}>
        <div className={styles.title} style={{ marginBottom: "20px", font: "24px Montserrat, sans-serif", fontWeight: "600" }}>
          Forgot Your Password?
        </div>
        <p>Enter your email and we'll send you a link to reset your password.</p>
        <form onSubmit={forgot_password} id="forgot_password_form"></form>
        <div className={styles.section}>
          <Form.Control
            type="email"
            value={email}
            placeholder="Email"
            onChange={(e) => setEmail(e.target.value)}
            form="forgot_password_form"
            required
          />
        </div>
        <div style={{ marginTop: "15px" }}>
          <Button type="submit" form="forgot_password_form" variant="primary">
            Reset Password
          </Button>
        </div>
      </div>
      <div className={styles.overlayRight}>
        <div className={styles.title}>Hello, Friend!</div>
        <div>
          Enter your personal details and access all features we have for you.
        </div>
        <div style={{ marginTop: "30px" }}>
          <Button
            variant="outline-primary"
            style={{ borderColor: "white", background: "none", color: "white" }}
            id="signUp"
          >
            <Link to="/sign-in">Sign In</Link>
          </Button>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
