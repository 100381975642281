import { useContext, Fragment } from "react";
import FeatureTab from "../components/FeatureTab";
import FeatureSettings from "../components/FeatureSettings";
import FeatureOutput from "../components/FeatureOutput";
import FeatureInput from "../components/FeatureInput";
import FeatureForm from "../components/FeatureForm";
import { restrictAccessModalContext } from "../context/RestrictAccessModalContext";
import { authContext } from "../context/AuthContext";


function Home() {
  const { currentUser } = useContext(authContext);
  const { openRestrictAccessModal } = useContext(restrictAccessModalContext);


  if (currentUser && !currentUser.emailVerified) {
    openRestrictAccessModal();
    return <></>
  }

  return (
    <Fragment>
          <FeatureForm />
          <FeatureSettings />
          <div className="content">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                height: "60%",
              }}
            >
              <FeatureTab />
              <FeatureOutput style={{ borderTop: "none", flexGrow: "1" }} />
            </div>
            <FeatureInput style={{ height: "40%" }} />
          </div>
    </Fragment>
  );
}

export default Home;
