import styles from "./Login.module.css";
import { Form, Button } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";
import { useState, useContext } from "react";
import { auth } from "../firebase";
import {
  updateProfile,
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";
import { authContext } from "../context/AuthContext";

function SignUp() {
  const { setTimeActive } = useContext(authContext);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [confirmEmail, setConfirmEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const navigate = useNavigate();

  const signup = (e) => {
    e.preventDefault();
    if (!checkEmail()) return;
    if (!checkPassword()) return;
    createUserWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        await updateProfile(auth.currentUser, {
          displayName: capitalize(firstName) + " " + capitalize(lastName),
        });
        await sendEmailVerification(userCredential.user);
        await setTimeActive(true);
        navigate("/verify-email");
      })
      .catch((err) => alert(err.message.slice(10)));
  };

  const checkEmail = () => {
    if (email !== confirmEmail) {
      alert("Emails do not match. Please try again.");
      return false;
    }
    return true;
  };

  const checkPassword = () => {
    if (password !== confirmPassword) {
      alert("Passwords do not match. Please try again.");
      return false;
    }
    return true;
  };

  const capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  return (
    <div className={styles.container}>
      <div className={styles.overlay}>
        <div className={styles.title} style={{ marginBottom: "30px" }}>
          Create Account
        </div>
        <form onSubmit={signup} id="signup_form"></form>
        <div
          className={styles.section}
          style={{ display: "flex", gap: "10px" }}
        >
          <Form.Control
            style={{ width: "calc(45% - 5px)" }}
            type="text"
            value={firstName}
            placeholder="First Name"
            onChange={(e) => setFirstName(e.target.value)}
            form="signup_form"
            required
          />
          <Form.Control
            style={{ width: "calc(45% - 5px)" }}
            type="text"
            value={lastName}
            placeholder="Last Name"
            onChange={(e) => setLastName(e.target.value)}
            form="signup_form"
            required
          />
        </div>
        <div className={styles.section}>
          <Form.Control
            type="email"
            value={email}
            placeholder="Email"
            onChange={(e) => setEmail(e.target.value)}
            form="signup_form"
            required
          />
        </div>
        <div className={styles.section}>
          <Form.Control
            type="email"
            value={confirmEmail}
            placeholder="Confirm Email"
            onChange={(e) => setConfirmEmail(e.target.value)}
            form="signup_form"
            required
          />
        </div>
        <div className={styles.section}>
          <Form.Control
            type="password"
            value={password}
            placeholder="Password"
            onChange={(e) => setPassword(e.target.value)}
            form="signup_form"
            required
          />
        </div>
        <div className={styles.section}>
          <Form.Control
            type="password"
            value={confirmPassword}
            placeholder="Confirm Password"
            onChange={(e) => setConfirmPassword(e.target.value)}
            form="signup_form"
            required
          />
        </div>
        <div style={{ marginTop: "15px" }}>
          <Button type="submit" form="signup_form" variant="primary">
            Sign Up
          </Button>
        </div>
      </div>
      <div className={styles.overlayRight}>
        <div className={styles.title}>Sign in here</div>
        <div>
          If you already have an account sign in
        </div>
        <div style={{ marginTop: "30px" }}>
          <Link to="/sign-in">
            <Button
              variant="outline-primary"
              style={{
                borderColor: "white",
                background: "none",
                color: "white",
              }}
              id="signUp"
            >
              Sign In
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default SignUp;
