import { useContext } from "react";
import { featureContext } from "../context/Feature";
import { formDataContext } from "../context/FormData";
import { authContext } from "../context/AuthContext";

function FeatureForm() {
  const { feature } = useContext(featureContext);
  const { formData, updateFormData } = useContext(formDataContext);
  const { currentUser } = useContext(authContext);

  const showSpinner = () => {
    updateFormData(feature, "spinner", true);
  };

  const hideSpinner = () => {
    updateFormData(feature, "spinner", false);
  };

  const openAlert = (error) => {
    updateFormData(feature, "alert", error);
  };

  const closeAlert = () => {
    updateFormData(feature, "alert", "");
  };

  const handleResponse = (response) => {
    if (!response.ok && response.status != 502) {
      throw Error(response.statusText);
    }

    if (response.status == 502) {
      response.json().then((text) => {
        openAlert(text);
      });
      return;
    }

    response.json().then((text) => {
      updateFormData(feature, "response", text);
    });
  };

  const handleError = (err) => {
    openAlert("We've experienced an unpredicted error. Please give it another try, and if the issue persists, notify the developers.");
    console.log(err);
  };

  const submitHandler = async (event) => {
    event.preventDefault();
    closeAlert();
    showSpinner();

    let token = "";
    try {
      token = await currentUser.getIdToken(true);
    }
    catch {
      token = "null";
    }

    try {
      const response = await fetch(formData[feature].endpoint, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData[feature]),
      });
      handleResponse(response);
    } 
    catch (err) {
      handleError(err);
    }
    finally {
      hideSpinner();
    }
  };

  return (
    <form id="form" onSubmit={submitHandler} style={{ display: "none" }}></form>
  );
}

export default FeatureForm;
