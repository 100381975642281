import { useContext } from "react";
import { playgroundContext } from "../context/PlaygroundContext";
import { authContext } from "../context/AuthContext";

function PlaygroundForm({ controllerRef }) {
  const { playground, setPlayground } = useContext(playgroundContext);
  const { currentUser } = useContext(authContext);

  const showSpinner = () => {
    setPlayground({ ...playground, spinner: true });
  };

  const hideSpinner = () => {
    setPlayground({ ...playground, spinner: false });
  };

  const openAlert = (text) => {
    setPlayground({ ...playground, responseAlert: text });
  };

  const closeAlert = () => {
    setPlayground(Object.assign(playground, { responseAlert: "" }));
  };

  const handleResponse = (response) => {

    if (!response.ok && response.status != 502) {
      throw Error(response.statusText);
    }

    if (response.status == 502) {
      response.json().then((text) => {
        openAlert(text);
      });
      return;
    }

    response.json().then((text) => {
      if (playground.model == "biogpt") {
        setPlayground({ ...playground, textarea: text });
      } else {
        const value = `${playground.textarea.trim()}\n\n${text.trim()}`;
        setPlayground({ ...playground, textarea: value });
      }
    });
  };

  const handleError = (err) => {
    if (controllerRef.current?.signal.aborted) return;
    openAlert("We've experienced an unpredicted error. Please give it another try, and if the issue persists, notify the developers.");
    console.log(err);
  };

  const submitHandler = async (event) => {
    event.preventDefault();
    closeAlert();
    showSpinner();
    const controller = new AbortController();
    controllerRef.current = controller;

    try {
      const token = await currentUser.getIdToken(true);
      const response = await fetch(playground.endpoint, {
        method: "POST",
        signal: controllerRef.current?.signal,
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(playground),
      })
      handleResponse(response);
    }
    catch (err) {
      handleError(err);
    }
    finally {
      hideSpinner();
    }
  };

  return (
    <>
      <form
        id="playgroundForm"
        onSubmit={submitHandler}
        style={{ display: "none" }}
      ></form>
    </>
  );
}

export default PlaygroundForm;
