import React, { useContext, useState } from "react";
import { Tab, Tabs, Button, Modal, Form } from "react-bootstrap";
import { featureContext } from "../context/Feature";

function FeatureTab() {
  const { feature, setFeature } = useContext(featureContext);

  const [tabState, setTabState] = useState(feature);

  const setTabStateHandler = (eventKey) => {
    setTabState(eventKey);
    setFeature(eventKey);
  }

  const [modalState, setModalState] = useState(false);

  const closeModal = () => setModalState(false);
  const openModal = () => setModalState(true);

  function tabSelectHandler(event) {
    if (event in [0, 1, 2]) {
      setTabStateHandler(event);
    } else {
      openModal();
    }
  }

  return (
    <div>
      <Tabs activeKey={tabState} onSelect={tabSelectHandler}>
        <Tab eventKey={0} title="News Summary"></Tab>
        <Tab eventKey={1} title="Title Suggestion"></Tab>
        <Tab eventKey={2} title="Key Events"></Tab>
      </Tabs>

      <Modal show={modalState} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title className="modal-title h5">Login Required</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          To access this feature, please login. It is free!
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={closeModal}>
            Login
          </Button>
          <Button variant="secondary" onClick={closeModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default FeatureTab;
