import { createContext, useState } from "react";

const authContext = createContext();

function Provider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [timeActive, setTimeActive] = useState(false)

  return (
    <authContext.Provider value={{ currentUser, setCurrentUser, timeActive, setTimeActive }}>
      {children}
    </authContext.Provider>
  );
}

export { authContext };
export default Provider;
