import { useContext, useState } from "react";
import { Form, Alert } from "react-bootstrap";
import DotPulse from "./DotPulse/DotPulse";
import { featureContext } from "../context/Feature";
import { formDataContext } from "../context/FormData";

function FeatureOutput({ style }) {
  const { feature } = useContext(featureContext);
  const { formData, updateFormData } = useContext(formDataContext);

  const closeAlert = () => {
    updateFormData(feature, "alert", "");
  };

  return (
    <div className="box" style={style}>
      {formData[feature].alert && (
        <Alert
          variant="warning"
          onClose={closeAlert}
          dismissible
        >
          <span>{formData[feature].alert}</span>
        </Alert>
      )}
      <div className="container">
        {formData[feature].spinner ? (
          <DotPulse />
        ) : (
          <Form.Control
            as="textarea"
            placeholder={formData[feature].responsePlaceholder}
            name="response"
            onChange={(event) =>
              updateFormData(feature, "response", event.target.value)
            }
            value={formData[feature].response}
          />
        )}
      </div>
    </div>
  );
}

export default FeatureOutput;
