import { createContext, useState } from "react";

const featureContext = createContext();

function Provider({ children }){
  const [feature, setFeature] = useState(0);

  return (
    <featureContext.Provider value={{ feature, setFeature }}>
      {children}
    </featureContext.Provider>
  );

}

export { featureContext };
export default Provider;
