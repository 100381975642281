import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDgYEztmAfSPwsvYXOP1gxHms-YTY8RczM",
  authDomain: "pharmabrain-19487.firebaseapp.com",
  projectId: "pharmabrain-19487",
  storageBucket: "pharmabrain-19487.appspot.com",
  messagingSenderId: "399562672940",
  appId: "1:399562672940:web:65d31b7b2751a5ee19ba89",
};

// Initialize Firebase and Firebase Authentication
const firebase = initializeApp(firebaseConfig);
const auth = getAuth(firebase);
export { auth };
