import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/bootstrap.scss";
import "./styles/index.css";
import App from "./App";
import FeatureProvider from "./context/Feature";
import FormDataProvider from "./context/FormData";
import PlaygroundContext from "./context/PlaygroundContext";
import AuthProvider from "./context/AuthContext";
import RestrictAccessModalProvider from "./context/RestrictAccessModalContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <AuthProvider>
    <RestrictAccessModalProvider>
    <FeatureProvider>
      <FormDataProvider>
        <PlaygroundContext>
          <App />
        </PlaygroundContext>
      </FormDataProvider>
    </FeatureProvider>
    </RestrictAccessModalProvider>
  </AuthProvider>
);
