import { createContext, useState } from "react";

const restrictAccessModalContext = createContext();

function Provider({ children }){
    const [restrictAccessModalState, setRestrictAccessModalState] = useState(false);
    
    const openRestrictAccessModal = () => setRestrictAccessModalState(true);
    const closeRestrictAccessModal = () => setRestrictAccessModalState(false);

  return (
    <restrictAccessModalContext.Provider value={{ restrictAccessModalState, openRestrictAccessModal, closeRestrictAccessModal }}>
      {children}
    </restrictAccessModalContext.Provider>
  );

}

export { restrictAccessModalContext };
export default Provider;
