import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar";
import Header from "./layout/Header";
import Main from "./layout/Main";
import Home from "./pages/Home";
import Playground from "./pages/Playground";
import history from './history';
import SignIn from "./pages/SignIn";
import SignUp from "./pages/SignUp";
import VerifyEmail from "./pages/VerifyEmail";
import ForgotPassword from "./pages/ForgotPassword";
import RestrictAccessModal from "./components/RestrictAccessModal";
import {useState, useEffect, useContext} from 'react'
import {auth} from './firebase'
import {onAuthStateChanged} from 'firebase/auth'
import {authContext} from './context/AuthContext'


function App() {
  const [initializing, setInitializing] = useState(true);
  const { setCurrentUser } = useContext(authContext);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
        setCurrentUser(user);
        if (initializing) setInitializing(false);
    });
  });

  if (initializing) return null;

  return (
    <BrowserRouter>
      <Header>
        <Navbar />
      </Header>
      <Main>
        <RestrictAccessModal/>
        <Routes history={history}>
          <Route path="/" element={<Home />} />
          <Route path="/playground" element={<Playground />} />
          <Route path="/sign-in" element={<SignIn />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/verify-email" element={<VerifyEmail />} />
        </Routes>
      </Main>
    </BrowserRouter>
  );
}

export default App;
