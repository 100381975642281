import styles from "./Login.module.css";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { sendEmailVerification } from "firebase/auth";
import { auth } from "../firebase";
import { authContext } from "../context/AuthContext";
import { useContext, useState, useEffect } from "react";

function VerifyEmail() {
  const { currentUser, timeActive, setTimeActive } = useContext(authContext);

  const [time, setTime] = useState(60);

  const resendEmailVerification = () => {
    sendEmailVerification(auth.currentUser)
      .then(() => {
        setTimeActive(true);
      })
      .catch((err) => {
        console.log(err);
        setTimeActive(true);
      });
  };

  useEffect(() => {
    let interval = null;
    if (timeActive && time !== 0) {
      interval = setInterval(() => {
        setTime((time) => time - 1);
      }, 1000);
    } else if (time === 0) {
      setTimeActive(false);
      setTime(60);
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [timeActive, time]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (currentUser?.emailVerified) {
        clearInterval(interval);
      } else {
        currentUser
          ?.reload()
          .then(() => {
            if (currentUser?.emailVerified) {
              clearInterval(interval);
              window.location.reload();
            }
          })
          .catch((err) => {
            console.log(err.message);
          });
      }
    }, 1000);
  });

  return currentUser?.emailVerified ? (
    <div className={styles.container}>
      <div className={styles.overlay} style={{ width: "100%" }}>
        <div className={styles.title} style={{ marginBottom: "30px" }}>
          <span style={{ color: "green" }}>Your email has been verified!</span>
        </div>
        <div>
          <p>Now you can enjoy all our features.</p>
        </div>
        <div style={{ marginTop: "10px" }}>
          <Link to="/">
            <Button variant="primary">Go to Home</Button>
          </Link>
        </div>
      </div>
    </div>
  ) : (
    <div className={styles.container}>
      <div className={styles.overlay} style={{ width: "100%" }}>
        <div className={styles.title} style={{ marginBottom: "30px" }}>
          Verify your Email Address
        </div>
        <div>
          <p>
            A verification email has been sent to:
            <br />
            <span>
              <b>{currentUser?.email}</b>
            </span>
          </p>
        </div>
        <div style={{ marginTop: "10px" }}>
          <p>Follow the instruction in the email to verify your account</p>
        </div>
        <div style={{ marginTop: "10px" }}>
          <Button
            variant="primary"
            onClick={resendEmailVerification}
            disabled={timeActive}
          >
            Resend Email {timeActive && time}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default VerifyEmail;
